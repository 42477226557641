import clipMIcon from "@/assets/images/clip_m.png";
import { Ticket, TicketComment } from "@/containers/Ticket/Ticket";
import {CustomMarkdown} from "@/components/CustomMarkdown";
import {useEffect, useRef} from "react";


interface Props {
  ticket?: Ticket
  className?: string
  ticketComments?: TicketComment[]
}

export default function MessageList({
  ticket,
  className,
  ticketComments,
}: Props) {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const comments = ticket ? ticket.data_ticket_comments : ticketComments ?? [];
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
  }, [comments])
  return (
    <div className={`messages ${className}`}>
      {comments?.map((item, index) => (
        <Comment
          key={`sales-ticket-message-list-${index}`}
          item={item}
          isInquiryUser={item.is_inquiry_user}
          ticket={ticket}
        />
      ))}
      <div ref={messagesEndRef}/>
    </div>
  );
}

const Comment = ({
  item,
  isInquiryUser,
  ticket,
}: {
  item: TicketComment;
  isInquiryUser: boolean;
  ticket?: Ticket;
}) => {
  const messageType = !isInquiryUser ? "received" : "sent";
  const messageSentInfo = `${item.created_group_by} ${item.created_user_by}`;

  return (
    <div>
      <div className={`${messageType}-message`}>
        <div className={`${messageType}-message-name`}>{messageSentInfo}</div>
        <div className={`${messageType}-message-box`}>
          <div>
            <CustomMarkdown content={item.comment} />
          </div>
          {item.attached_files.length > 0 && (
            <>
              <hr />
              {item.attached_files.map((file, index) => (
                <div key={index} className="uk-flex uk-flex-middle">
                  <img
                    src={clipMIcon}
                    width={16}
                    height={16}
                    className="uk-margin-small-right"
                  />
                  <a href={file.filepath} target="_blank">
                    {file.original_filename}
                  </a>
                </div>
              ))}
            </>
          )}
        </div>
        <div className={`${messageType}-message-time`}>{item.created}</div>
      </div>
    </div>
  );
};