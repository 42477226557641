import {ApplyForm, useApplyFormStore} from "@/stores/applyForm";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLoadingStore} from "@/stores/loading";
import * as Config from "@/config";
import * as Common from "@/utils/common";
import {postApply} from "@/api";
import {useMessageModalStore} from "@/stores/message";
import moment from "moment";

export default function ApplyConfirm() {
  const navigate = useNavigate();
  const setLoading = useLoadingStore((state) => state.setLoading);
  const setMessage = useMessageModalStore((state) => state.setMessage);

  const {applyForm, attributes, areas, estimateAmount, taxRate} =
    useApplyFormStore();

  const [departmentTitle, setDepartmentTitle] = useState<string>("部 署");
  const [positionTitle, setPositionTitle] = useState<string>("役 職");
  const [nameTitle, setNameTitle] = useState<string>("氏 名");
  const [selectedAreas, setSelectedAreas] = useState<string>("");

  useEffect(() => {
    if (applyForm && attributes.length > 0) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      setSelectedAreas(
        Common.getSelectedAreaList(applyForm.areas, areas)
          .map((area) => area.name)
          .join(",")
      );
      const id = attributes.find(
        (item: any) =>
          item.column_name === Config.ATTRIBUTE_COLUMN_NAME_GROUP_TYPE
      )?.id;
      if (id) {
        const groupTypeCode = applyForm.attributes[id].split(
          Config.SPLIT_CODE
        )[1];
        if (groupTypeCode == 7) {
          // 団体区分：「大学等研究機関」
          setDepartmentTitle("大学・研究機関名");
          setPositionTitle("職位");
          setNameTitle("責任者氏名");
        } else {
          setDepartmentTitle("部 署");
          setPositionTitle("役 職");
          setNameTitle("氏 名");
        }
      }
    } else {
      navigate("/apply");
    }
  }, [applyForm, attributes]);

  const onClickCancel = () => {
    navigate(-1);
  };

  const onClickSend = async () => {
    if (applyForm == null) {
      return;
    }
    const files = await Common.filesToPostParam(applyForm);
    const postData = {
      project_id: Config.PROJECT_ID,
      project_category_id: Config.PROJECT_CATEGORY_ID,
      area_id: Config.AREA_ID,
      block_id: Config.BLOCK_ID,
      remarks: "",
      data_attribute_values: Common.inputAttributeToPostParam(
        attributes,
        applyForm
      ),
      attached_file: files,
      applies: {
        amount: estimateAmount,
        consumption_tax: estimateAmount * taxRate,
      },
      apply_areas_list: Common.areasToPostParam(applyForm),
    };
    setLoading(true);
    postApply(postData)
      .then((res) => {
        if (res.data.result != false) {
          navigate("/apply_complete");
        }
      })
      .catch((err) => {
        console.error(err);
        setMessage(Config.MESSAGE_NO_E37);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getValue = (columnName: string) => {
    const id = attributes.find(
      (item: any) => item.column_name === columnName
    )?.id;
    if (!id) {
      return "";
    }
    const composed = applyForm?.attributes?.[id];
    if (!composed) {
      return "";
    }
    return Common.extractValue(composed);
  };

  const notEstimate = getValue(Config.ATTRIBUTE_COLUMN_NAME_APPLY_TYPE) == 'サンプルデータのみ申込' || getValue(Config.ATTRIBUTE_COLUMN_NAME_INQUIRER_TYPE) == '道路管理者'
  console.log(applyForm);
  console.log(attributes);
  console.log(areas);
  return (
    <div className="uk-flex uk-flex-column uk-flex-middle uk-margin-large-bottom">
      <div className="sub-header">
        <p className="uk-text-large uk-text-bold uk-margin-remove">
          DRM-DB 申込内容確認画面
        </p>
      </div>
      <div className="uk-flex uk-width-3-4 uk-margin-top">
        <div className="step-content">
          <ul className="step">
            <li>メールアドレスの入力</li>
            <li>メールアドレスの送信完了</li>
            <li>お客様情報の登録</li>
            <li className="is-current">登録内容の確認</li>
            <li>お申し込みの完了</li>
          </ul>
        </div>
      </div>
      <div className="uk-width-3-4 uk-margin-top">
        <div className="uk-text-medium">▼申込者情報</div>
        <table className="confirm-tbl">
          <tbody>
          <tr>
            <td>新規/継続</td>
            <td>
              <p>{getValue(Config.ATTRIBUTE_COLUMN_NAME_CONTRAT)}</p>
            </td>
          </tr>
          <tr>
            <td>申込区分</td>
            <td>
              <p>{getValue(Config.ATTRIBUTE_COLUMN_NAME_APPLY_TYPE)}</p>
            </td>
          </tr>
          <tr>
            <td>道路管理者区分</td>
            <td>
              <p>{getValue(Config.ATTRIBUTE_COLUMN_NAME_INQUIRER_TYPE)}</p>
            </td>
          </tr>
          <tr>
            <td>団体区分</td>
            <td>
              <p>{getValue(Config.ATTRIBUTE_COLUMN_NAME_GROUP_TYPE)}</p>
            </td>
          </tr>
          <tr>
            <td>法人・団体名</td>
            <td>
              <p>{getValue(Config.ATTRIBUTE_COLUMN_NAME_GROUP_NAME)}</p>
            </td>
          </tr>
          <tr>
            <td>{departmentTitle}</td>
            <td>
              <p>{getValue(Config.ATTRIBUTE_COLUMN_NAME_DEPARTMENT)}</p>
            </td>
          </tr>
          {getValue(Config.ATTRIBUTE_COLUMN_NAME_SECTION) && (
            <tr>
              <td>課名</td>
              <td>
                <p>{getValue(Config.ATTRIBUTE_COLUMN_NAME_SECTION)}</p>
              </td>
            </tr>
          )}
          {getValue(Config.ATTRIBUTE_COLUMN_NAME_UNIT) && (
            <tr>
              <td>室名または係名</td>
              <td>
                <p>{getValue(Config.ATTRIBUTE_COLUMN_NAME_UNIT)}</p>
              </td>
            </tr>
          )}
          <tr>
            <td>{positionTitle}</td>
            <td>
              <p>{getValue(Config.ATTRIBUTE_COLUMN_NAME_POSITION)}</p>
            </td>
          </tr>
          <tr>
            <td>{nameTitle}</td>
            <td>
              <p>
                {getValue(Config.ATTRIBUTE_COLUMN_NAME_LAST_NAME)}{" "}
                {getValue(Config.ATTRIBUTE_COLUMN_NAME_FIRST_NAME)}
              </p>
            </td>
          </tr>
          <tr>
            <td>ふりがな</td>
            <td>
              <p>
                {getValue(Config.ATTRIBUTE_COLUMN_NAME_LAST_NAME_KANA)}{" "}
                {getValue(Config.ATTRIBUTE_COLUMN_NAME_FIRST_NAME_KANA)}
              </p>
            </td>
          </tr>
          <tr>
            <td>電 話</td>
            <td>
              <p>{getValue(Config.ATTRIBUTE_COLUMN_NAME_PHONE_NUMBER)}</p>
            </td>
          </tr>
          <tr>
            <td>住 所</td>
            <td>
              <p>〒{getValue(Config.ATTRIBUTE_COLUMN_NAME_ZIP_CODE)}</p>
              <p>
                {getValue(Config.ATTRIBUTE_COLUMN_NAME_PREF)}
                {getValue(Config.ATTRIBUTE_COLUMN_NAME_CITY)}
                {getValue(Config.ATTRIBUTE_COLUMN_NAME_HOUSE_NUMBER)}{" "}
                {getValue(Config.ATTRIBUTE_COLUMN_NAME_BUILDING_NAME)}
              </p>
            </td>
          </tr>
          <tr>
            <td>メールアドレス</td>
            <td>
              <p>{getValue(Config.ATTRIBUTE_COLUMN_NAME_EMAIL_ADDRESS)}</p>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-3-4 uk-margin-top">
        <div className="uk-text-medium">▼申込DB情報</div>
        <table className="confirm-tbl">
          <tbody>
          {selectedAreas && (
            <tr>
              <td>申込エリア</td>
              <td>{selectedAreas}</td>
            </tr>
          )}
          {getValue(Config.ATTRIBUTE_COLUMN_NAME_DB_TYPE) && (
            <tr>
              <td>DB種類</td>
              <td>{getValue(Config.ATTRIBUTE_COLUMN_NAME_DB_TYPE)}</td>
            </tr>
          )}
          {getValue(Config.ATTRIBUTE_COLUMN_NAME_DB_EDITION) && (
            <tr>
              <td>版</td>
              <td className="line-break">
                {getValue(Config.ATTRIBUTE_COLUMN_NAME_DB_EDITION)}
              </td>
            </tr>
          )}
          <tr>
            <td>利用目的（用途）</td>
            <td>{getValue(Config.ATTRIBUTE_COLUMN_NAME_PURPOSE)}</td>
          </tr>
          {getValue(Config.ATTRIBUTE_COLUMN_NAME_PRESENTATION_SCHEDULE) && (
            <tr>
              <td>研究成果の発表予定</td>
              <td>
                <p>
                  予定時期：
                  {moment(
                    getValue(
                      Config.ATTRIBUTE_COLUMN_NAME_PRESENTATION_SCHEDULE
                    )
                  ).format("YYYY年MM月")}{" "}
                  頃<br/>
                  発表先：
                  {getValue(
                    Config.ATTRIBUTE_COLUMN_NAME_PRESENTATION_DESTINATION
                  )}
                  <br/>
                  発表形式：
                  {getValue(Config.ATTRIBUTE_COLUMN_NAME_PRESENTATION_TYPE)}
                </p>
              </td>
            </tr>
          )}
          {getValue(Config.ATTRIBUTE_COLUMN_NAME_DELIVERY_DATE) && (
            <tr>
              <td>納品希望日</td>
              <td>{getValue(Config.ATTRIBUTE_COLUMN_NAME_DELIVERY_DATE)}</td>
            </tr>
          )}
          {getValue(Config.ATTRIBUTE_COLUMN_NAME_DESTINATION) && (
            <tr>
              <td>データ送付先</td>
              <td>{getValue(Config.ATTRIBUTE_COLUMN_NAME_DESTINATION)}</td>
            </tr>
          )}
          {getValue(Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_GROUP_NAME) && (
            <tr>
              <td>送付先情報</td>
              <td>
                <div>
                  <p>
                    法人・団体名：
                    {getValue(
                      Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_GROUP_NAME
                    )}
                  </p>
                  <p>
                    部署名・所属：
                    {getValue(
                      Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_DEPARTMENT
                    )}
                  </p>
                  <p>
                    氏名：
                    {getValue(
                      Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_LAST_NAME
                    )}{" "}
                    {getValue(
                      Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_FIRST_NAME
                    )}
                  </p>
                  <p>
                    電話番号：
                    {getValue(
                      Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_PHONE_NUMBER
                    )}
                  </p>
                  <p>
                    〒
                    {getValue(
                      Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_ZIP_CODE
                    )}
                  </p>
                  <p>
                    {getValue(Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_PREF)}
                    {getValue(Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_CITY)}
                    {getValue(
                      Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_HOUSE_NUMBER
                    )}{" "}
                    {getValue(
                      Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_BUILDING_NAME
                    )}
                  </p>
                </div>
              </td>
            </tr>
          )}
          {getValue(Config.ATTRIBUTE_COLUMN_NAME_BILLING) && (
            <tr>
              <td>請求先</td>
              <td>{getValue(Config.ATTRIBUTE_COLUMN_NAME_BILLING)}</td>
            </tr>
          )}
          {getValue(Config.ATTRIBUTE_COLUMN_NAME_BILLING_GROUP_NAME) && (
            <tr>
              <td>請求先情報</td>
              <td>
                <div>
                  <p>
                    法人・団体名：
                    {getValue(
                      Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_GROUP_NAME
                    )}
                  </p>
                  <p>
                    部署名・所属：
                    {getValue(
                      Config.ATTRIBUTE_COLUMN_NAME_BILLING_DEPARTMENT
                    )}
                  </p>
                  <p>
                    氏名：
                    {getValue(
                      Config.ATTRIBUTE_COLUMN_NAME_BILLING_LAST_NAME
                    )}{" "}
                    {getValue(
                      Config.ATTRIBUTE_COLUMN_NAME_BILLING_FIRST_NAME
                    )}
                  </p>
                  <p>
                    電話番号：
                    {getValue(
                      Config.ATTRIBUTE_COLUMN_NAME_BILLING_PHONE_NUMBER
                    )}
                  </p>
                  <p>
                    〒
                    {getValue(Config.ATTRIBUTE_COLUMN_NAME_BILLING_ZIP_CODE)}
                  </p>
                  <p>
                    {getValue(Config.ATTRIBUTE_COLUMN_NAME_BILLING_PREF_NAME)}
                    {getValue(Config.ATTRIBUTE_COLUMN_NAME_BILLING_CITY_NAME)}
                    {getValue(
                      Config.ATTRIBUTE_COLUMN_NAME_BILLING_HOUSE_NUMBER
                    )}{" "}
                    {getValue(
                      Config.ATTRIBUTE_COLUMN_NAME_BILLING_BUILDING_NAME
                    )}
                  </p>
                </div>
              </td>
            </tr>
          )}
          {getValue(Config.ATTRIBUTE_COLUMN_NAME_SPECIAL_NOTES) && (
            <tr>
              <td>特記事項</td>
              <td>{getValue(Config.ATTRIBUTE_COLUMN_NAME_SPECIAL_NOTES)}</td>
            </tr>
          )}
          {(applyForm?.files0 ?? []).length > 0 && (
            <tr>
              <td>ファイル添付</td>
              <td>
                {applyForm?.files0?.map((item, index) => {
                  return <p key={index}>{item.file.name}</p>;
                })}
              </td>
            </tr>
          )}
          {(applyForm?.files1 ?? []).length > 0 && (
            <tr>
              <td>ファイル添付（利用申請書）</td>
              <td>
                {applyForm?.files1?.map((item, index) => {
                  return <p key={index}>{item.file.name}</p>;
                })}
              </td>
            </tr>
          )}
          {(applyForm?.files2 ?? []).length > 0 && (
            <tr>
              <td>ファイル添付（承諾書）</td>
              <td>
                {applyForm?.files2?.map((item, index) => {
                  return <p key={index}>{item.file.name}</p>;
                })}
              </td>
            </tr>
          )}
          {(applyForm?.files3 ?? []).length > 0 && (
            <tr>
              <td>ファイル添付（複製依頼書）</td>
              <td>
                {applyForm?.files3?.map((item, index) => {
                  return <p key={index}>{item.file.name}</p>;
                })}
              </td>
            </tr>
          )}
          <tr>
            <td>個人情報の取扱い</td>
            <td>個人情報の取扱いについて同意する</td>
          </tr>
          <tr>
            <td>見積金額（税込）</td>
            <td>
              <div className="uk-flex">
                <p className="uk-background-light-red">
                  {Common.formatPrice(estimateAmount * (1.0 + taxRate))}円
                  【内消費税10%（地方消費税含む）:
                  {Common.formatPrice(estimateAmount * taxRate)}円】
                </p>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <div className="uk-flex uk-flex-column uk-flex-middle uk-margin-top">
          <p className="uk-margin-remove">
            {notEstimate ? '送信ボタンを押すと、申込が完了します。' : '送信ボタンを押すと、申込が完了し、見積書がメールで添付されます。' }
          </p>
        </div>
        <div className="uk-flex uk-flex-center uk-margin-small-top uk-margin-small-bottom">
          <button
            className="uk-button--m uk-button-cancel"
            onClick={onClickCancel}
          >
            戻る
          </button>
          <button
            className="uk-button--m uk-button-refer uk-margin-left"
            onClick={onClickSend}
          >
            送信
          </button>
        </div>
      </div>
    </div>
  );
}
