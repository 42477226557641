import { useApplyFormStore } from "@/stores/applyForm";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ApplyComplete() {
  const navigate = useNavigate();

  const applyFormStore = useApplyFormStore();
  const onClickClose = () => {
    applyFormStore.setApplyForm(null);
    applyFormStore.setAreas([]);
    applyFormStore.setEstimateAmount(0);
    window.close();
  };

  return (
    <div className="uk-flex uk-flex-column uk-flex-middle">
      <div className="sub-header">
        <p className="uk-text-large uk-text-bold uk-margin-remove">
          DRM-DB　申込完了画面
        </p>
      </div>
      <div className="uk-flex uk-width-3-4 uk-margin-top">
        <div className="step-content">
          <ul className="step">
            <li>メールアドレスの入力</li>
            <li>メールアドレスの送信完了</li>
            <li>お客様情報の登録</li>
            <li>登録内容の確認</li>
            <li className="is-current">お申し込みの完了</li>
          </ul>
        </div>
      </div>
      <div className="uk-flex uk-flex-column uk-flex-middle uk-width-3-4 uk-margin-large-top">
        <p className="uk-text-large uk-text-bold uk-text-center">
          フォームからのお申込が完了しました。
        </p>
        <p className="uk-text-default uk-text-medium uk-text-center">
          受付完了のメールをお送りしました。
          <br />
          {applyFormStore.showQuotationOrderText && <>お見積書・発注書を合わせて送付しております。</>}
          <br />
          <br />
          受付内容の変更を行いたい場合には、
          <br />
          <a href="https://www.drm.jp/contact/" target="_blank">
            <u>お問い合わせフォーム</u>
          </a>
          からご連絡下さい。
        </p>
        <button
          className="uk-button--m uk-button-cancel uk-margin-top"
          onClick={onClickClose}
        >
          閉じる
        </button>
        <br />
        <p className="uk-text-default uk-text-medium uk-text-center">
          閉じるボタンが効かない場合はブラウザを閉じてください。
        </p>
      </div>
    </div>
  );
}
